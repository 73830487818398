/* CSS Document */

:root{
	--red : #F00;
	--dark-red : #F30;	
	--do-color: #000;
	--mark-color: #000;
	--case-color: #FFF;
	--hands-color: #F00;
}
/********* About Clock { *********/
.setRed{
	background: #F00;	
}
.clockcontainer {
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  border: 3px #000 solid;
  background: transparent;
  box-shadow: 0px 0px 3px 3px #000000;
  opacity: 0;
  z-index: 9999;
}

.clockcase2 {
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background: var(--case-color);
  border-radius: 50%;
  box-shadow: inset 40px 40px 90px rgba(0, 0, 0, 0.2),
    inset 10px 10px 30px rgba(0, 0, 0, 0.5), 20px 20px 30px rgba(0, 0, 0, 0.4),
    40px 40px 60px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1;
}
.clockcase2.pmNow{
	box-shadow: inset 40px 40px 90px rgba(94,255, 4, 0.2),
    inset 10px 10px 30px rgba(0, 0, 0, 0.5), 20px 20px 30px rgba(0, 0, 0, 0.4),
    40px 40px 60px rgba(0, 0, 0, 0.4) !important;	
}

.centerwasher {
  position: absolute;
  width: 3%;
  height: 3%;
  background: var(--do-color);
  top: 50.3%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 99;
}

.citylabel {
  position: absolute;
  color: var(--red);
  top: 19%;
  left: 50%;
  height: 8%;
  transform: translate(-50%, 0%);
  text-align: center;
  font-family: arial;
  font-size: 100%;
}

.datelabel {
  position: absolute;
  color: #000000;
  bottom: 30%;
  left: 50%;
  height: 8%;
  transform: translate(-50%, 0%);
  text-align: center;
  font-family: arial;
  font-size: 85%;
}

.hourhand,
.secondhand,
.minutehand {
  background: var(--hands-color);
  position: absolute;
  z-index: 99;
}

.hourhand {
  width: 30%;
  top: 49%;
  left: 45.5%;
  height: 2%;
  background-color: var(--dark-red);
  transform-origin: 16%;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.4);
}

.minutehand {
  width: 45%;
  height: 1%;
  top: 49.5%;
  left: 45%;
  transform-origin: 11%;
  border-top-right-radius: 30%;
  border-bottom-right-radius: 30%;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.4);
}

.secondhand {
  width: 45%;
  height: 0.5%;
  top: 50.1%;
  left: 44.2%;
  background: var(--do-color);
  transform-origin: 13%;
  box-shadow: -5px -7px 10px rgba(0, 0, 0, 0.4);
}

.minutedotright  {
  height: 100%;
  width: 1%;
  background-color: var(--do-color);
  border-radius: 50%;
  float: right;
}

.minutedotleft {
  height: 100%;
  width: 1%;
  background-color: var(--do-color);
  border-radius: 50%;
  float: left;
}

.minutediv {
  position: absolute;
  border: none;
  height: 1%;
  width: 90%;
  background: transparent;
  top: 49.5%;
  left: 50%;
}

.leftmark {
  width: 7%;
  height: 100%;
  background: var(--mark-color);
  float: left;
}

.rightmark {
  width: 7%;
  height: 100%;
  background:  var(--mark-color);
  float: right;
}
/**********} end Clock **********/