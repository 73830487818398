/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import 'material-icons/iconfont/material-icons.css';

@font-face {
  font-family: 'lcd';
  src: url('./assets/fonts/lcd.ttf');
  src: url('./assets/fonts/lcd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'segoe-ui';
  src: url('./assets/fonts/segoeui.ttf');
  src: url('./assets/fonts/segoeui.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  position: relative;
  min-height: 100%;
}
:root{
	--highLight-color:  #d40511;
	--middleLight-color: #d40511F9;
	--lowLight-color: 	#f5f5f5;
	--lightBg-color: #f5f5f5;
	--dark-color: #666;
	--border-green-color: #090;
	--bg-green-color: #090;
	--green-color: #090;
	--yellow-color: #fecb2f;
	--yellow-color-opac: #fecb2f33;	
	--orange-color: #F90;
	--border-yellow: 1px var(--yellow-color) solid;
}
*{
	font-family: 'segoe-ui';	
}
header{
	background-color: var(--yellow-color) !important;
}
.theIcon{
	text-shadow: 2px 2px 5px #000 !important;
}
.highlight{
	color: 	var(--highLight-color) !important;
}
.green-color{
	color: var(--green-color);	
}
.yellow{
	color: 	 var(--yellow-color);
}
.orange{
	color: 	 var(--orange-color);
}
.blink{
	animation: blink 1.5s infinite linear;
}
.text-bold{
	font-weight: bold !important;	
}

@keyframes blink{
	0%{ opacity: .2;}
	50%{ opacity: 1}
	100%{ opacity: .2}
}

.pageTitle h3{
	/*text-transform: uppercase;*/
	font-weight: bold;
	font-size: 1.5rem;
	color: var(--highLight-color);
	border-bottom: 5px var(--highLight-color) solid;	
}
.separator{
	float: none;
	clear: both;
	display: block;
	margin: 10px auto;
	height: 1px;
	border-bottom:	1px var(--dark-color) solid;
	opacity: 0.2;
}
.separator.dotted{
	border-bottom:	1px var(--dark-color) dotted !important;
}
.expansion-indicator{
	transition: all ease .5s;
}
.card-header.collapsed .expansion-indicator{
	transform: rotate(180deg);	
}
/*.card-header:not(collapsed) .expansion-indicator{
	transform: rotate(180deg);
}*/

.card{
 /* box-shadow: none !important;*/
 margin-top: 0px;
 	margin-bottom: 15px;
}
.card-header{
	padding: 5px 10px !important;	
}
.card-body{
	padding: 10px !important;	
}
.card-header h3,.card-header h4 {
	margin: 0px !important;
	font-weight: bold !important;
}
.card.card-yellow{
	/*margin-bottom: 15px;	*/
}
.card.card-yellow .card-header{
	background-color: var(--yellow-color);
}
.card.mini-card{
	padding: 3px;
	margin-bottom: 5px !important;
	font-size: 12px;
	border-color: var(--yellow-color);
}
.card.mini-card div>div:first-child{
	min-width: 140px;
}



.card.card-red .card-header{
	background-color: var(--highLight-color);
}

.card.card-red .card-header h3,.card.card-red .card-header h4 {
	color: white !important;
	/*margin-bottom: 15px;	*/
}
.card.card-green{
	border-color: var(--green-color);
}
.card.card-green .card-header{
	border-bottom-color: var(--green-color);
}
.card.card-border-green{
	border-color: var(--green-color);
}
.card.card-border-orange{
	border-color: var(--orange-color);
}
.card.card-border-orange .card-header{
	border-bottom-color: var(--orange-color);
}
.card.card-border-green{
	border-color: var(--green-color);
}

.obs{
    max-height: 150px;
    overflow-y: scroll;
    cursor: pointer;
}
.leftContent{
	/*border: 1px #0CC solid !important;*/
	padding: 15px;	
	background:  var(--lightBg-color);
}

/*** Form ***/
.control-label{
	display: table-cell;
	height: 28px;
	vertical-align: middle;
	font-weight: bold;
	font-size: small;
	color: #000;
}
*.form-control:not(textarea)  {
	height: 28px !important;
	/*height: auto !important;*/
	line-height : 1;
	font-size: unset;
	appearance: auto !important;
}
*.form-control:is(select)  {
	padding: 0px 12px;
}
.form-control::placeholder {
    opacity: .5 !important;
}

textarea.form-control{
	font-size: unset !important;	
}
.btn.btn-submit,.btn.btn-cancel {
	margin: 0px 10px;
	padding: 5px 25px;
}
.btn.btn-submit{
	text-transform: none;
	color: var(--border-green-color);
	border: 1px var(--border-green-color) solid;
	box-shadow: 0px 1px 1px 1px var(--border-green-color);
}
.btn.btn-cancel{
	text-transform: none;
	color: var(--highLight-color);
	border: 1px var(--highLight-color) solid;
	box-shadow: 0px 1px 1px 1px var(--highLight-color);
}
/**** /Form ***/
/**** Dispatch ***/
.tab-wrapper .mat-tab-label {
		opacity: 1 !important;	
	}
.tab-wrapper .mat-tab-label .mat-tab-label-content{
		font-weight: bold !important;
		text-transform: uppercase !important;
	}
	
.tab-wrapper .mat-tab-label-active{
		color: var(--highLight-color);
		background: white;
	}
.tab-wrapper .mat-tab-group.mat-primary .mat-ink-bar{
		background-color: var(--highLight-color) !important;
	}
.tab-wrapper .mat-tab-body-content .container{
		padding: 0px;
	}
.tab-wrapper .th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
		width: 150px;	
		text-align: end;
	}
/***** Fin Dispatch ****/
/*** Data Table ****/
table.dataTable{
	margin-bottom: 15px !important;	
}
table.dataTable thead > tr > th.sorting_asc::before, table.dataTable thead > tr > th.sorting_desc::after, table.dataTable thead > tr > td.sorting_asc::before, table.dataTable thead > tr > td.sorting_desc::after {
    opacity: 1 !important;
    color: var(--highLight-color);
}
table.dataTable tbody tr.rouge {
	 border-bottom: 2px var(--highLight-color) solid;
}

table.dataTable tbody tr.orange {
	 border-bottom: 2px #FC0 solid;
}
table.dataTable tbody tr.jaune {
	 border-bottom: 2px #FFC solid;
}
table.dataTable tbody tr.vert {
	 border-bottom: 2px #3C6 solid;
}
table.dataTable tbody tr.gris {
	 border-bottom: 2px #CCC solid;
	 color: #999 !important;
}



table.dataTable tbody tr {
	border-bottom: 1px var(--lowLight-color) solid;	
}

table.dataTable tbody tr.odd:hover,
table.dataTable tbody tr.even:hover,
table.dataTable tbody tr:nth-child(2n):hover, 
table.dataTable tbody tr:nth-child(2n+1):hover {
	background: var(--yellow-color-opac) !important; 
}

tr.odd{
	background: var(--lowLight-color) !important; 
}

table.dataTable tbody td {
	vertical-align: middle;	
}

table.dataTable tbody .btn-tbl{
	margin: 0px;
	text-transform: none !important;
}
table.dataTable .progress{
	position: absolute;
    bottom: 0;
    left: 0;
	width: 100%;
    background: linear-gradient(45deg,transparent,green, yellow, orange, red);
	border: 0px none;
	overflow: hidden;
   
}

table.dataTable .progress_bar{
	position: absolute;
	right: 0;
	width: 100%;  
	height: 10px;
	background: var(--lightBg-color);
	transition: width 1s linear;
	 
}

table.dataTable tr[data-start]{
	position: relative;
}

.jtreat.rouge{
	font-weight: bold;
	color: var(--highLight-color);
}
.jtreat.orange{
	font-weight: bold;
	color: orange;	
}
.jtreat.orange{
	font-weight: bold;
	color: var(--yellow-color);	
}

table.dataTable tr[data-start]:nth-child(2n+1){
	background: var(--lightBg-color);
}

div.dt-datetime table td.selectable button:hover {
    background: var(--highLight-color) !important;
}
div.dt-datetime table td.selectable.selected button {
    background: var(--lightBg-color) !important;
    color: var(--highLight-color) !important;
	border: 1px var(--highLight-color) solid !important;
	font-weight: bold;
}


@media screen and (max-width:768px){
    .hidemobile{
        display: none;
    }
}
/**** End data Table ***/
.theDate, theHour{
	display: block;
}
.theDate{
	font-family: 'segoe-ui';
	color: #CCC;
}
.theHour{
	font-family: 'lcd';
	font-size: 40px;
	margin-top: 10px;
	color: #0F0;
	text-shadow: 2px 2px 3px #000 inset;
	/*tranform: translate(0px, 50px); */
}

.headerClock .theHour{
	color: #000 !important;
	font-size:2rem;
	text-align: center;
}
.headerClock .theDate{
	color: #000 !important;
	font-size: x-large;
	text-align: center;
}


/************** table  DashScreen ***********/
/********* table ***********/
.airport .hideCol{
	display: none;	
}
.airport .highlight{
	color: #fff !important;
	font-size: 23px;	
}

.airport .table{
	width: 100vw !important;
	font-size: 20px;	
	transition: all 1s ease;
}
.airport  .table.setScroll thead{
	position: sticky;
	top:0;
}
.airport .table td,.airport .table th{
	vertical-align: middle !important;
	border: none;
}
.airport .table thead{
	color: #fff;
 	text-shadow: 1px 1px 2px #000;
	border-top: 0px none !important; 
	box-shadow: -5px -1px 2px 5px #0000008a inset;
	background: var(--bg-gradient);
}
.airport .table thead tr,.airport .table tr{
	background: var(--bg-gradient);	
}
.airport .table thead th{
	vertical-align: middle;
	border: none;
	text-transform: capitalize;
}

.airport .table tr{
	height: 55px;
	border-top: 2px #000 solid;
	box-shadow: -5px 0px 3px 3px #0000008a inset;
}
.airport .table tr{
	margin: 5px;
}

.airport .table td{
	padding: 2px 5px;
	color: #FFFFAB;
	font-weight:bolder;
}

.airport .table td span{
	display:block;
	/*height: 45px;*/
	padding: 5px 15px;
	color: inherit !important;
	border: 1px #495057 solid;
	border-radius: 5px;
	background: linear-gradient(45deg, #1a1a26, #19212e, #153740);
	box-shadow: 4px 3px 3px 3px #0005 inset
}
.airport .table td.xcouleur span:before{
	content: "";
	float:left;
	display: inline-block;
	margin-left: -5px;
	margin-right: 5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: transparent;
	transition: all .5s ease;
}
.airport .table td.xcouleur span.rouge:before{
	background-color: #F00 !important;
}
.airport .table td.xcouleur span.vert:before{
	background-color: #090 !important;
}

.airport .table td.xcouleur span.jaune:before{
	background-color: #FF0 !important;
}
.airport .table td.xcouleur span.orange:before{
	background-color: #F60 !important;
}



.airport .table tr.rouge td span{
  background: linear-gradient(45deg, #430000, #2e1919, #570000);
}
.airport .table tr.orange td span{

    background: linear-gradient(45deg, #1a1713, #2e1919, #945a01);

}
.airport .table tr.jaune td span{

    background: linear-gradient(45deg, #060500, #322707, #403b00);

}
.airport .box{
	height: -webkit-fill-available;
	height: -moz-fill-available;
}
.airport .box img.dashimg{
	display: block !important;
	margin: 0px auto; 
	width: initial;
	height: -webkit-fill-available;

}
.airport #bx>.box{
	height: calc(100vh - 135px) !important; /*100vh !important;	*/
	
}
/******end table ********/
#exampleModalLabel{
	margin:0px;
	padding: 0px;
	color:var(--highLight-color) !important;
}
.modal-header .modal-title{
	margin:0px;
	padding: 0px;
	color:var(--highLight-color) !important;
}

.lien{
	cursor: pointer;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #ec232b;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #ec232b;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
.boardbody{
	height: calc(100vh - 135px);
	/*background: var(--bg-gradient);*/
}
#msgBox{
	position: fixed;
	bottom: -3px;
	left: 0px;	
	display: block;
	width: 100vw;
	color: #f00;
	font-weight: bold;
	font-size: 25px;
	background: var(--yellow-color);
	z-index: 999;
	/*border: 1px #f00 solid;*/
}

.screenbox{
	height: 40vh;
	width: 20vw;
	border: 1px #000 solid;
	border-radius: 10px;
	box-shadow: 5px 5px 5px #000;
}
	.screenbox a{
		display: block;
		height: 100%;
		width: 100%;
		font-size: 2em;
   		color: white;
    	background: rgba(0,0,0,.3)
	}

